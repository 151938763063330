import { createMemo } from 'solid-js';
import BgSplashBanner from '~/assets/backgrounds/tempSplashBanner.png';
import { LET_US_GUIDE_YOUR_HOME, BANNER_TITLE, HOME } from '~/assets/strings';
import usePresenter from '~/framework/hooks/usePresenter';
import { useLocalization } from '~/hooks/useLocalization';
import { useTitle } from '~/hooks/useTitle';
import { PropertyForYou } from '~/pages/home/PropertyForYou';
import { CompanyStylePresenter } from '~/presenters/CompanyStylePresenter';

export default function Index() {
  const { model: companyStyling } = usePresenter(CompanyStylePresenter);

  const { t } = useLocalization();
  useTitle({ title: t(HOME) });

  const cover = createMemo(() => {
    if (companyStyling()?.isCompanyInfoLoaded) {
      return companyStyling()?.cover || BgSplashBanner;
    }
    return '';
  });

  return (
    <div class="w-full">
      <div
        class="relative flex h-[600px] w-full justify-center bg-cover bg-center bg-no-repeat"
        style={{ 'background-image': `url(${cover()})` }}>
        <div class="flex w-max-container-width flex-col items-center  pt-[135px]">
          <div class="text-white">{t(LET_US_GUIDE_YOUR_HOME)}</div>
          <div class="w-[900px] text-center text-[70px] font-semibold text-white">{t(BANNER_TITLE)}</div>
        </div>
      </div>
      <PropertyForYou />
    </div>
  );
}
